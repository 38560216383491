import http from "../utils/request";
// import main from "../main";
import apiconfig from "./apiconfig";

import Cookie from 'js-cookie'
console.log(apiconfig.url)
console.log('12312');


const mes_url = apiconfig.url+'/mes/api'
const main_url = apiconfig.url+'/api'
export const ptint_card = (data) => {
    //返回一个json对象
    console.log('发送打印');
    return http.post('http://localhost:5000/print_label',data)
}
export const on_board = (data) => {
    //返回一个json对象
    console.log('上机');
    return http.post(mes_url+'/platform/production/task/personnel/on/board',{
        data,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'fx-auth-token': localStorage.getItem('token_mes')
       }

    })
}
export const getuniqueCode = (data) => {
    //返回一个json对象
    console.log('请求一物一码');
    return http.get(mes_url+'/material/material/findByPage',{
        params:data,
        headers: {
            'fx-auth-token': localStorage.getItem('token_mes')
       }
    })
}
// 请求生产线数据
export const prodTask = (data) => {
    //返回一个json对象
    console.log('请求生产线数据');
    return http.get(mes_url+'/platform/production/prodTask/uniqueCode',{
        params:data,
        headers: {
            'fx-auth-token': localStorage.getItem('token_mes')
       }
    })
}
export const Check_auth = () => {
    //返回一个json对象
    console.log('验证身份')
    return http.get(mes_url+'/app/menu/user/PC',{
        headers: {
            'fx-auth-token': localStorage.getItem('token_mes')
        }
    })
}
export const login = (data) => {
    //返回一个json对象
    console.log(mes_url,'dgrtd')
    return http.post(mes_url+'/security/session', data, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    })
}
//请求首页数据
export const getData = () => {
    //返回一个promise对象，首页数据（home）
    return http.get('home/admin/')
    
}

//请求数据
export const getdata = (data) => {
    //返回一个json对象
    return http.post(main_url+'/auth/',{ ...data, token: Cookie.get('token')})
}

export const addUser = (data) => {
    //新增用户列表
    return http.post('/user/add/',data)
}
export const edimm = (data) => {
    //新增用户列表
    return http.post(main_url+'/passworod/',data)
}

export const editUser = (data) => {
    //编辑用户列表
    return http.post('/user/edit/',data)
}

export const delUser = (data) => {
    //删除用户列表
    return http.post('/user/del/',data)
}

export const getMenu = (data) =>{
    //登录并请求数据
    //return http.post('/permission/getMenu',data)
    return http.post(main_url+'/login/check/',data)
    
}

export const deltoken = (data) =>{
    //清除服务端token
    return http.post(main_url+'/logout/',data)
}

export const unique_code_query = (data) => {
    //返回一个json对象
    return http.post(main_url+'/mes/unique_code_query/', data)
}
//请求数据


