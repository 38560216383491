import Vue from 'vue'
import App from './App.vue'
// import { Row,Button, Dialog, Input, Checkbox, Select, Option, Tag, Table, TableColumn, Form, FormItem, Pagination, Menu, MenuItem, Submenu, MenuItemGroup, Breadcrumb, BreadcrumbItem, Dropdown, DropdownMenu, DropdownItem, CheckboxGroup, Container, Aside, Header, Main, Card, Col } from 'element-ui';//按需引入
import ElementUI  from 'element-ui';//全局引用
import 'element-ui/lib/theme-chalk/index.css';
import router from './router/index.js'; //./router/index.js
import store from './store';
import Cookie from 'js-cookie';
import VueQRCodeComponent from 'vue-qrcode-component'
Vue.component('qr-code', VueQRCodeComponent)
import VTooltip from 'v-tooltip'
Vue.use(VTooltip)
// import { Message,MessageBox } from 'element-ui';

// Vue.prototype.$confirm = MessageBox.confirm;
// // Vue.prototype.$MessageBox = MessageBox;
// Vue.prototype.$message = Message;
// Vue.config.productionTip = false


//按需引入

// Vue.use(Col)
// Vue.use(Card)
// Vue.use(DropdownItem)
// Vue.use(DropdownMenu)
// Vue.use(Dropdown)
// Vue.use(BreadcrumbItem)
// Vue.use(Breadcrumb)
// Vue.use(MenuItemGroup)
// Vue.use(Submenu)
// Vue.use(MenuItem)
// Vue.use(Menu)
// Vue.use(FormItem)
// Vue.use(Pagination)
// Vue.use(TableColumn)
// Vue.use(Table)
// Vue.use(Option)
// Vue.use(Select)
// Vue.use(Tag)
// Vue.use(CheckboxGroup)
// Vue.use(Checkbox)
// Vue.use(Input)
// Vue.use(FormItem)
// Vue.use(Form)
// Vue.use(Dialog)
// Vue.use(Row)
// Vue.use(Button)
// Vue.use(Container)
// Vue.use(Aside)
// Vue.use(Header)
// Vue.use(Main)
// 全局引入
Vue.use(ElementUI)

//添加全局前置导航守卫
router.beforeEach((to,from,next) =>{
  //判断token存不存在
  const token = Cookie.get('token')
  //判断token不存在，说明当前用户未登录，应该跳转至登录页
  if (!token && to.name !== 'login') {
    next({name:'login'})
  } else if (token && to.name === 'login'){//token存在，说明用户登录，此时跳转到首页
    next({ name:'home' })
  }else {
    next()
  }
})

new Vue({
  el: '#app',
  store,
  router,
  render: h => h(App),
  created(){
    store.commit('addMenu',router)
  }
}).$mount('#app')


const hostname = window.location.hostname; // 获取当前页面的主机名
const port = window.location.port; // 获取当前页面的端口号
const protocol = window.location.protocol; // 获取当前页面的协议
let baseURL = `${protocol}//${hostname}`;
if (port) {
  baseURL += `:${port}`;
}

export default {

url: `${baseURL}/api`,
// url:'http://10.22.11.30:8888/api',
// mes_url:'http://mes.eprosemi.com:8008/api',
// url: 'http://localhost:8000/api'
// url: 'http://127.0.0.1:8000/api'
}

