import { render, staticRenderFns } from "./bj.vue?vue&type=template&id=bb22eb00&scoped=true&"
import script from "./bj.vue?vue&type=script&lang=js&"
export * from "./bj.vue?vue&type=script&lang=js&"
import style0 from "./bj.vue?vue&type=style&index=0&id=bb22eb00&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bb22eb00",
  null
  
)

export default component.exports