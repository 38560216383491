<template>
  <el-container>
    <span v-if="zhanshitext" class="draggable" :style="{ top: position.top + 'px', left: position.left + 'px' }"
      @mousedown="onMouseDown">
      {{ zhanshitext }}
    </span>
    <el-header><el-dialog title="U9账号" :visible.sync="dialogVisible" width="50%" @close="dialogVisible = false">
        <el-form :model="form" label-width="120px">
          <el-form-item label="U9用户名">
            <el-input v-model="form.username"></el-input>
          </el-form-item>
          <el-form-item label="密码">
            <el-input v-model="form.password"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="loging">确定</el-button>
            <el-button @click="dialogVisible = false">取消</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
      <div @click="showDialog">
        <el-alert :closable="false" v-if="dialogVisible" center title="登录失败,请检查用户名密码" type="error" effect="dark">
        </el-alert>
      </div>
      <el-radio v-model="operate" label="1" border>百级</el-radio>
      <el-radio v-model="operate" label="2" border>全局</el-radio>
      规格型号：
      <el-select v-model="specificationModel" filterable
        :remote-method="query => remoteMethod({ 'specificationModel': query })" remote reserve-keyword
        placeholder="规格型号" style="width: 200px;" collapse-tags clearable>
        <el-option v-for="item in specificationModel_options" :key="item" :label="item" :value="item">
        </el-option>
      </el-select>
      刻字码：
      <el-select v-model="letteringCode" filterable :remote-method="query => remoteMethod({ 'letteringCode': query })"
        remote reserve-keyword placeholder="刻字码" style="width: 200px;" collapse-tags clearable
        @change="handleSpecificationChange">
        <el-option v-for="item in letteringCode_options" :key="item" :label="item" :value="item">
        </el-option>
      </el-select>
      <el-button type="success" :plain="shangji" :disabled="current_baiji" @click="on_boardChanged">上机</el-button>
      <el-button type="warning" round @click="showDialog">当前用户：{{ form['username'] }}</el-button>
      <input type="text" v-model="zhanshitext">
    </el-header>

    <!-- <qr-code v-if="uniqueCode" :key="uniqueCode" :text="uniqueCode" -->
    <el-main>
      <div class="content-wrapper">

        <div class="info-container">
          <div class="info-item"><span class="key">一物一码：</span><span class="value">{{ uniqueCode }}</span></div>
          <div class="info-item"><span class="key">规格型号：</span><span class="value">{{
      processedSpecificationModel(specificationModel) }}</span></div>
          <div class="info-item"><span class="key">刻字码：</span><span class="value">{{ letteringCode }}</span></div>
          <div class="info-item"><span class="key">毛坯编号：</span><span class="value">{{ blank_number }}</span></div>
          <div class="info-item"><span class="key">原材料批次：</span><span class="value">{{ batch_of_silicon_rods }}</span>
          </div>

        </div>
      </div>
      <div v-if="uniqueCode" class="qr-code-container">
        <qr-code :key="uniqueCode" :text="uniqueCode" :size=200 color="#000"
          :style="{ boxSizing: 'border-box', border: '1px solid #070707', padding: '5px' }" bg-color="#fff"
          error-level="L"></qr-code>
      </div>
    </el-main>
    <el-dialog title="提示" :visible.sync="tanchuang" width="30%">
      <span>mes中，当前物流工序不在百级</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="tanchuang = false">确 定</el-button>
      </span>
    </el-dialog>
  </el-container>

</template>

<script>
import { unique_code_query } from '../api'
import { login } from '../api'
import { Check_auth } from '../api'
import { getuniqueCode } from '../api'
import { getdata } from '../api'
// 生产线数据
import { prodTask } from '../api'
import { on_board } from '../api'


import Cookie from 'js-cookie'
export default {
  watch: {
    // 监听数据属性
    // 'uniqueCode': function (newVal, oldVal) {
    //   // this.get_prodTask()
    //   // this.shangji=true;
    // },
    'specificationModel': function (newVal, oldVal) {
      const filteredDatas = this.datas.filter(item => item.specification_model === newVal && item.lettering_code === this.letteringCode);
      console.log(filteredDatas)
      this.zhanshitext = ''
      if (filteredDatas.length == 1) {
        this.uniqueCode = filteredDatas[0].unique_code;
        this.letteringCode = filteredDatas[0].lettering_code;
        this.batch_of_silicon_rods = filteredDatas[0].batch_of_silicon_rods;
        this.blank_number = filteredDatas[0].blank_number;
        this.remoteMethod({ 'letteringCode': '', 'NO': '' })
      } else {
        console.log('TTTTTTTTTTTTTTTTTTTTTTTT')
        this.remoteMethod({})
      }
    },
    'letteringCode': function (newVal, oldVal) {
      if (!this.specificationModel) {
        console.log('判断成功')
        const filteredDatas = this.datas.filter(item => item.lettering_code === newVal);
        console.log(filteredDatas)
        if (filteredDatas.length == 1) {
          this.uniqueCode = filteredDatas[0].unique_code;
          this.specificationModel = filteredDatas[0].specification_model;
          this.batch_of_silicon_rods = filteredDatas[0].batch_of_silicon_rods;
          this.blank_number = filteredDatas[0].blank_number;
          this.remoteMethod({ 'specificationModel': '', 'NO': '' })
        } else {
          this.remoteMethod({})
        }
      }else{
        console.log('判断失败')
        const filteredDatas = this.datas.filter(item => item.lettering_code === newVal && item.specification_model === this.specificationModel);
        console.log(this.datas,filteredDatas,newVal,this.specificationModel)
        if (filteredDatas.length == 1) {
          this.uniqueCode = filteredDatas[0].unique_code;
          this.specificationModel = filteredDatas[0].specification_model;
          this.batch_of_silicon_rods = filteredDatas[0].batch_of_silicon_rods;
          this.blank_number = filteredDatas[0].blank_number;
        }else{
          this.$message({
            message: '找不到一物一码，请直接搜刻字码',
            type: 'warning'
          });
        }
      }
    }
  },
  data() {
    return {
      zhanshitext: '',
      dragging: false,
      initialX: 0,
      initialY: 0,
      offsetX: 0,
      offsetY: 0,
      position: { top: 0, left: 0 },
      task_id: '',
      tanchuang: false,
      current_baiji: false,
      shangji: true,
      operate: '1',
      dialogVisible: false,
      specificationModel_options: ['qqq', 'rrr', 'gggg'],
      letteringCode_options: ['qqq', 'rrr', 'gggg'],
      datas: '',
      form: {
        username: '王兵艳',
        password: '123456',
      },
      appKey: '773fdf10-54b5-4b6a-be54-8afd620287f1',
      tenantCode: 'ys-mes-prod',
      type: 'phone',
      // 原材料批次
      batch_of_silicon_rods: '',
      // 毛坯编号
      blank_number: '',
      // 父级编号
      parentId: '',
      // 供应商
      supplier: '',
      // 物料编码
      materialCode: '',
      // 规格型号
      specificationModel: '',
      // 一物一码
      uniqueCode: '',
      // 刻字码
      letteringCode: '',
      pgs: 1000,
      pgn: 1,
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight
    }
  },
  methods: {
    processedSpecificationModel(model) {
      // 检查字符串是否包含汉字
      const containsChinese = /[\u4e00-\u9fa5]/.test(model);

      // 如果包含汉字，处理字符串
      if (containsChinese) {
        // 定义正则表达式来匹配字母（不区分大小写）
        const regex = /[A-Za-z]/g;

        // 获取字符串中所有匹配字母的位置
        let lastLetterIndex = -1;
        let match;
        while ((match = regex.exec(model)) !== null) {
          lastLetterIndex = match.index;
        }

        // 如果找到了字母，则截取字符串到最后一个字母（包含最后一个字母）
        if (lastLetterIndex !== -1) {
          return model.slice(0, lastLetterIndex + 1);
        }
      }

      // 如果不包含汉字或没有找到字母，返回原始字符串
      return model;
    },
    onMouseDown(event) {
      this.dragging = true;
      this.initialX = event.clientX - this.offsetX;
      this.initialY = event.clientY - this.offsetY;

      document.addEventListener('mousemove', this.onMouseMove);
      document.addEventListener('mouseup', this.onMouseUp);
    },
    onMouseMove(event) {
      if (this.dragging) {
        event.preventDefault();

        this.offsetX = event.clientX - this.initialX;
        this.offsetY = event.clientY - this.initialY;

        this.position.top = this.offsetY;
        this.position.left = this.offsetX;
      }
    },
    onMouseUp() {
      this.dragging = false;
      document.removeEventListener('mousemove', this.onMouseMove);
      document.removeEventListener('mouseup', this.onMouseUp);
    },
    handleSpecificationChange() {

    },
    handleResize() {
      // 更新窗口尺寸数据
      this.windowWidth = window.innerWidth;
      this.windowHeight = window.innerHeight;
    },
    showDialog() {
      this.dialogVisible = true;
    },
    on_boardChanged() {
      getdata({ specificationModel: this.zhanshitext ? this.zhanshitext : this.processedSpecificationModel(this.specificationModel), letteringCode: this.letteringCode, uniqueCode: this.uniqueCode, tablename: 'Final_pack', operate: '1' }).then((data) => {
        if (data.data?.message == '会话失效') {
          //清楚cookie的token信息
          Cookie.remove('token')
          //清楚Cookie中的menu
          Cookie.remove('menu')
          //跳转到登录页
          this.$router.push('/login')
        }
        else if (data.data.code == "200") {
          console.log('传入最终包装成功')
          this.shangji = false;
          this.$message({
            message: '上机成功',
            type: 'success'
          });
        }
        else if (data.data.error == "555") {
          this.$message({
            message: '传入百级失败，请联系管理员',
            type: 'warning'
          });
          //重新获取列表的接口
        }
        else if (data.data.error === "000") {
          this.$message({
            message: '没有该表权限，请联系管理员',
            type: 'warning'
          });
          //重新获取列表的接口

        }

      })
      Check_auth().then(({ data }) => {
        if (data.code == 0) {
          this.dialogVisible = false;
        } else {
          this.loging()
        }
      }).catch(() => {
        this.loging()
      });
      // on_board({ taskId: this.task_id }).then(({ data }) => {
      //   if (data.code == 0) {
      //     this.$message({
      //       message: '恭喜你，上机成功',
      //       type: 'success'
      //     });

      //   } else {
      //     this.$message({
      //       message: '上机失败，请使用手机扫描上机',
      //       type: 'error',
      //       duration: 5000 // 显示时间为 5 秒
      //     });
      //   }
      // })
    },
    get_prodTask() {
      Check_auth().then(({ data }) => {
        if (data.code == 0) {
          this.dialogVisible = false;
        } else {
          this.loging()
        }
      }).catch(() => {
        this.loging()
      });
      prodTask({ uniqueCode: this.uniqueCode, preLoad: true }).then(({ data }) => {
        if (data.code == 0) {
          const get_data = data.ok
          const techStepList = get_data.techStepList
          console.log(techStepList)
          const currentStep = techStepList.find(step => Number(step.currentStep) === 1);
          console.log(currentStep)

          if (currentStep?.subCategory.includes('百级')) {
            this.current_baiji = false;
            this.task_id = currentStep.id;
          } else {
            this.tanchuang = true
            this.current_baiji = true;
          }
        } else {
          this.dialogVisible = true;
        }
      })
    },
    remoteMethod(query) {
      console.log("remote method", query)
      if ('letteringCode' in query && query.letteringCode != null && query.letteringCode != '') {
        this.specificationModel = ''
      }
      // 取消前一次调用
      if (this.timer) {
        clearTimeout(this.timer)
      }
      this.timer = setTimeout(() => {
        try {
          unique_code_query({ specificationModel: 'specificationModel' in query ? query['specificationModel'] : this.specificationModel, letteringCode: 'letteringCode' in query ? query['letteringCode'] : this.letteringCode, operate: this.operate, }).then(({ data }) => {
            const states = data;
            this.datas = states
            if (states.length > 0) {
              // 使用 map 来提取 specification_model 并去重
              this.specificationModel_options = [...new Set(states.map(item => item.specification_model))];
              // 使用 map 来提取 lettering_code 并去重
              this.letteringCode_options = [...new Set(states.map(item => item.lettering_code))];
            }
          })
        } catch (error) {
          console.error('请求数据失败:', error);

          this.loading = false; // 请求失败时同样需要隐藏加载状态
          this.$message({
            message: '查询失败，请联系管理员',
            type: 'warning'
          });
        }
      }, 250)
    },
    loging() {
      console.log('触发登录')
      login({ username: this.form.username, password: this.form.password, appKey: this.appKey, tenantCode: this.tenantCode, type: this.type }).then(({ data }) => {
        if (data.code == 0) {
          const token = data.ok.token
          Cookie.set('fx-auth-token-0', token)
          Cookie.set('fx-auth-token-size', 1)
          localStorage.setItem('token_mes', token);
          this.dialogVisible = false;
        } else {
          this.dialogVisible = true;
        }
      })
    },
    beforeMount() {
      // 在这里编写需要在页面加载前执行的代码
      console.log('dsaygusydfg')
      const token = localStorage.getItem('token_mes');
      if (token) {
        Check_auth().then(({ data }) => {
          if (data.code == 0) {
            this.dialogVisible = false;
          } else {
            this.loging()
          }
        }).catch(() => {
          this.loging()
        });
      } else {
        this.loging()
      }
    }
  },
  mounted() {
    // 添加窗口尺寸变化的事件监听器
    console.log('dsaygusydfg')
    const token = localStorage.getItem('token_mes');
    if (token) {
      Check_auth().then(({ data }) => {
        if (data.code == 0) {
          this.dialogVisible = false;
        } else {
          this.loging()
        }
      }).catch(() => {
        this.loging()
      });
    } else {
      this.loging()
    }
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    // 组件销毁前移除事件监听器
    window.removeEventListener('resize', this.handleResize);
  },
  computed: {
    qrSize() {
      // 计算视口的短边的50%
      const minSide = Math.min(this.windowWidth, this.windowHeight);
      return minSide * 0.7;
    }
  },
}
</script>

<style lang="less" scoped>
.el-header {
  margin-top: 20px;
  height: 28px !important;
}

.el-main {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 20px;
  margin-top: 5%;
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.qr-code-container {
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 1000;
}

/* 媒体查询用于横屏 */
@media (orientation: landscape) {
  .qr-code-container {
    top: 20px;
    /* 在横屏时定位到顶部 */
    bottom: auto;
    /* 取消底部定位 */
  }

  .el-main {
    /* 在横屏时使用2%的上边距 */
    margin-top: 0px;
  }
}

.info-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.info-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5px 0;
  padding: 10px 0;
  width: 100%;
  text-align: center;
  font-size: 6vw;
  font-weight: bold;
  border-bottom: 1px solid #ccc;
}

.draggable {
  width: 60%;
  font-size: 6vw;
  font-weight: bold;
  background-color: #fff;
  color: #000;
  // text-align: center;
  line-height: 100px;
  position: absolute;
  cursor: move;
  z-index: 9999;
}

.key {
  text-align: right;
  margin-right: 10px;
  width: 40%;
}

.value {
  flex: 1;
  text-align: left;
}
</style>