<template >
  <el-form class="login-beijin">
    <el-form ref="form" label-width="70px" :inline="true" class="login-container" :model="form" :rules="rules">
      <h3 class="login_title">密码修改</h3>
      <el-form-item label="旧密码" prop="oldpassword">
        <el-input type="password" v-model="form.oldpassword" placeholder="请输入旧密码" @keyup.enter.native="submit">
        </el-input>
      </el-form-item>
      <el-form-item label="新密码" prop="newpassword">
        <el-input type="password" v-model="form.newpassword" placeholder="请输入新密码" @keyup.enter.native="submit">
        </el-input>
      </el-form-item>
      <el-form-item label="新密码" prop="confirmpassword">
        <el-input type="password" v-model="form.confirmpassword" placeholder="重复新密码" @keyup.enter.native="submit">
        </el-input>
      </el-form-item>
      <el-button @click="submit" style="margin-left: 105px;margin-top: 10px;" type="primary">确认修改</el-button>
    </el-form>
  </el-form>
</template>
<script>
import { edimm } from '../api'
import Cookie from 'js-cookie'
export default {
  data() {
    return {
      form: {
        oldpassword: '',
        newpassword: '',
        confirmpassword: '',
      },
      rules: {
        oldpassword: [
          { required: true, trigger: 'blur', message: '请输入旧密码' }
        ],
        newpassword: [
          { required: true, trigger: 'blur', message: '请输入新密码' },
          { validator: this.validatePassword, trigger: 'blur', message: '两次输入的密码不一致' }
        ],
        confirmpassword: [
          { required: true, trigger: 'blur', message: '请再次输入密码' },
          { validator: this.validatePassword, trigger: 'blur', message: '两次输入的密码不一致' }
        ]
      }
    }
  },
  methods: {
    submit() {
      this.$refs.form.validate((valid) => {
                if (valid) {
                  console.log('123')
                  
                  edimm({token: Cookie.get('token'),jmm: this.form.oldpassword ,xmm:this.form.newpassword}).then((data) => {
                    console.log(data)
                    if(data.data.message == "密码修改成功"){
                      this.$message({
                          message: data.data.message,
                          type: 'success'
                      });
                    }else if (data.data.message == "旧密码错误"){
                      this.$message.error(data.data.message)
                    }
                    else if (data.data.message == "令牌失效"){
                      this.$message({
                          message: data.data.message,
                          type: 'warning'
                      });
}
                  })
                }})

    },
    validatePassword(rule, value, callback) {
      if (value !== this.form.newpassword) {
        callback(new Error('两次输入的密码不一致'));
      } else {
        callback();
      }
    },

  },
}
</script>

<style lang="less" scoped>
.draggable {
  width: 100px;
  height: 100px;
  background-color: #f0f0f0;
}

.login-beijin {
  // background-image: url("../assets/logo_background.png");
  background-repeat: no-repeat;
  background-size: cover;
  // width: 100%;
  border: 1px solid #eaeaea;
  // margin: auto;
  padding: 35px 35px 15px 35px;
  border-radius: 15px;
  box-shadow: 0 0 25px #cac6c6;
  box-sizing: border-box;
  background-color: #e5e3e9;
  height: 100%;
}

.login-container {
  width: 350px;
  border: 1px solid #eaeaea;
  margin: 180px auto;
  padding: 35px 35px 15px 35px;
  background-color: #fff;


  border-radius: 15px;
  box-shadow: 0 0 25px #cac6c6;
  box-sizing: border-box;

  .login_title {
    text-align: center;
    margin-bottom: 40px;
    color: #505458;
  }

  .el-input {
    width: 198px;
  }
}
</style>