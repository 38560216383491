<template>
  <div id="app">
<!--          <el-row>
          <el-button disabled>默认按钮</el-button>
          <el-button type="primary" disabled>主要按钮</el-button>
          <el-button type="success" disabled>成功按钮</el-button>
          <el-button type="info" disabled>信息按钮</el-button>
          <el-button type="warning" disabled>警告按钮</el-button>
          <el-button type="danger" disabled>危险按钮</el-button>
        </el-row> -->
          <!-- 路由匹配到的组件将渲染在这里 -->
        <router-view></router-view>
  </div>
</template>

<script>


export default {
  name: 'App'
}
</script>

<style lang="less" >
html,body,h3,p {
margin: 0;
padding: 0;
}
</style>
