const hostname = window.location.hostname; // 获取当前页面的主机名
const port = window.location.port; // 获取当前页面的端口号
const protocol = window.location.protocol; // 获取当前页面的协议
let baseURL = `${protocol}//${hostname}`;
if (port) {
  baseURL += `:${port}`;
}
// baseURL='http://shengming.fun'
export default {
url: `${baseURL}`,
// url: `http://qs.eprosemi.com:8010`,
// url:'http://10.22.11.30:8888/api',
// mes_url:'http://mes.eprosemi.com:8008/api',
// url: 'http://localhost:8000/api'
// url: 'http://127.0.0.1:8000/api'
}