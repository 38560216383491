import Vue from 'vue'
import VueRouter from 'vue-router'
//2、将组件和路由做映射
// import home from '../views/home.vue'
// import user from '../views/user.vue'
// import main from '../views/main.vue'
// import mall from'../views/mall.vue'
// import pageone from'../views/pageone.vue'
// import pagetwo from'../views/pagetwo.vue'
// import Login from '../views/login.vue'
import personal from '../views/personal.vue'
import DevicesView2 from '../views/DevicesView.vue'
import bj from '../views/bj.vue'
//import biaodan from '@/components/biaodan'



Vue.use(VueRouter)

//1、创建路由组件
//2、将组件和路由做映射
//3、创建routes实例

const routes = [
    //主路由
    {
        path: '/',
        component: () => import('../views/main.vue'),
        name:'Main',
        redirect:'home',//重定向
        children: [
            // //子路由
            // { path: '/home',name:'home', component: home },//首页
            // { path: '/user',name:'user', component: user },//用户管理
            // { path: '/mall',name:'mall', component: mall },//商品管理
            // { path: '/pageone',name:'page1', component: pageone },//页面1
            // { path: '/pagetwo',name:'page2', component: pagetwo },//页面2
            // { path: '/biaodan/:id',name: 'biaodan',component: biaodan},
            {
                path: '/personal',
                name:'personal',
                component: personal
            },
        ]
    },
    
    {
        path: '/login',
        name:'login',
        component: () => import('../views/login.vue')
    },
    {
        path: '/DevicesView',
        name:'DevicesView',
        component: () => import('../views/DevicesView.vue')
    },
    {
        path: '/bj',
        name:'bj',
        component: () => import('../views/bj.vue')
    },

 ]

 //3、创建routes实例
const router = new VueRouter({
  routes // (缩写) 相当于 routes: routes
}) 

export default router




