<template>
    <div style="padding: 20px;">
        <div class="biao-ti">
            设备运行状态
        </div>
        <div style="margin-left: 30%;display: flex;flex-direction: row;">
            <div style="height: 20px;width: 20px;background-color: #75adf8;">
            </div>
            正常生产
            <div style="height: 20px;width: 20px;background-color: #fff;border:1px solid hsl(0, 0%, 80%);">
            </div>
            正常停机
            <div style="height: 20px;width: 20px;background-color: yellow;margin-left: 20px;"></div>
            程序超时
            <div style="height: 20px;width: 20px;background-color: #e8544d;margin-left: 20px;"></div>
            停机超时
            <div class="scz" style="height: 20px;width: 20px;margin-left: 20px;"></div>
            生产中
            <div class="blinking-circle2"></div>
            生产计时
            <div class="blinking-circle3"></div>
            停机超13分钟计时
            <div class="bloke downtime-button" style="right: 30px;width: 135px;">24小时停机超时累计</div>
        </div>

        <div style="display: flex;flex-direction: row;margin-top: 15px;">

            <div
                style="border: 1px solid hsl(0, 0%, 80%);border-radius: 6px; display: flex;flex-direction: column;width: 100%;margin-left: 8px;padding: 15px;">
                <div @mouseenter="pauseAnimation" @mouseleave="resumeAnimation"
                    style="width: 100%;height: calc(100vh - 250px);overflow: hidden;">
                    <div style="width: 100%;" :style="{ transform: `translateY(${offset}px)`, transition: gdqzTransition }">
                        <div ref="wrapper" style="width: 100%;">
                            <div ref="jsgd" v-for="item in mydata"
                                style="display: flex;flex-direction: row;position: relative;background-color: azure;margin-top: 10px;border: 1px solid hsl(78, 40%, 49%);">
                                <div style="width: 100px; font: 14px sans-serif;margin: 10px;">{{ item['name'] }}</div>
                                <div
                                    style="display: flex;flex-direction: row; margin-top: 14px;border-top:2px solid rgb(79, 111, 198);width: calc(100% - 390px);height: 30px;position: relative;overflow: hidden;">
                                    <div v-for="i2, index in item['time']" style="">
                                         <!-- 1.判断是否是正常生产 -->
                                        <div v-if="i2[1] && i2[4] !== '停机'"
                                            v-tooltip="{ content: i2, placement: 'top', trigger: 'hover' }" class="bloke"
                                            :style="{ border: '1px solid #ccc', left: lf(i2[0]) + 'px', width: wd(i2) + 'px', background: 'linear-gradient(90deg, rgba(117,173,248, 0.5) 0 ' + i2[2] / i2[3] * 100 + '%, rgba(255,255,0, 0.5) ' + i2[2] / i2[3] * 100 + '%) 0px 0px/100% 100%' }">
                                        </div>
                                        <!-- 2.判断结束时间为空且最后是最后一项，正常生产中 -->
                                        <div v-else-if="!i2[1] && index === item['time'].length - 1"
                                            v-tooltip="{ content: i2, placement: 'top', trigger: 'hover' }"
                                            class="bloke scz"
                                            :style="{ border: '1px solid #ccc', left: lf(i2[0]) + 'px', width: '100%' }">
                                        </div>
                                        <!-- 3.判断结束时间不为空且是停机状态，停机时间 -->
                                        <div v-else-if="i2[1] && i2[4] === '停机'"
                                            v-tooltip="{ content: i2, placement: 'top', trigger: 'hover' }" class="bloke"
                                            :style="{ border: '1px solid #ccc', left: lf(i2[0]) + 'px', width: wd(i2) + 'px', background: 'linear-gradient(90deg, #fff 0 ' + (780 / 86400) * stWidth + 'px, rgba(255,0,0) ' + (780 / 86400) * stWidth + 'px) 0px 0px/100% 100%' }">
                                        </div>
                                        <div v-if="!i2[1] && index === item['time'].length - 1"
                                            v-tooltip="{ content: i2, placement: 'top', trigger: 'hover' }"
                                            class="bloke"
                                            :style="{ border: '1px solid #ccc', left: (lf(i2[0]) + i2[2] * 3600/86400 * stWidth)+ 'px', width: '100%' }" style="background-color: rgb(255, 255, 0 ,0.4);">
                                        </div>
                                    </div>
                                    <div v-if="item['time'][item['time'].length - 1][1]" class="bloke"
                                        :style="{ border: '1px solid #ccc', left: lf(item['time'][item['time'].length - 1][1]) + 'px', width: '100%', background: 'linear-gradient(90deg, #fff 0 ' + (780 / 86400) * stWidth + 'px, rgba(255,0,0) ' + (780 / 86400) * stWidth + 'px) 0px 0px/100% 100%' }">
                                    </div>
                                    
                                </div>
                                <div style="width: 26px;">
                                    <div
                                        :class="{ 'blinking-circle3': item['time'][item['time'].length - 1][1] && if_red2(item['time'][item['time'].length - 1][1]), 'blinking-circle2': !item['time'][item['time'].length - 1][1] }">
                                    </div>
                                </div>
                                <div style="width: 120px;font-size: 16px;padding: 0;margin: 0;">{{
                                    countdown(item['time'][item['time'].length - 1][1] ? item['time'][item['time'].length -
                                        1][1] : item['time'][item['time'].length - 1][0]) }}</div>
                                <div
                                    style="width: 100px;font-size: 16px;padding: 5px;margin: 5px;border: 2px solid #d0e343;">
                                    {{
                                        Beyond(item['time']) }}分钟</div>
                            </div>
                        </div>
                        <div  style="width: 100%;">
                            <div  v-for="item in mydata"
                                style="display: flex;flex-direction: row;position: relative;background-color: azure;margin-top: 10px;border: 1px solid hsl(78, 40%, 49%);">
                                <div style="width: 100px; font: 14px sans-serif;margin: 10px;">{{ item['name'] }}</div>
                                <div
                                    style="display: flex;flex-direction: row; margin-top: 14px;border-top:2px solid rgb(79, 111, 198);width: calc(100% - 390px);height: 30px;position: relative;overflow: hidden;">
                                    <div v-for="i2, index in item['time']" style="">
                                         <!-- 1.判断是否是正常生产 -->
                                        <div v-if="i2[1] && i2[4] !== '停机'"
                                            v-tooltip="{ content: i2, placement: 'top', trigger: 'hover' }" class="bloke"
                                            :style="{ border: '1px solid #ccc', left: lf(i2[0]) + 'px', width: wd(i2) + 'px', background: 'linear-gradient(90deg, rgba(117,173,248, 0.5) 0 ' + i2[2] / i2[3] * 100 + '%, rgba(255,255,0, 0.5) ' + i2[2] / i2[3] * 100 + '%) 0px 0px/100% 100%' }">
                                        </div>
                                        <!-- 2.判断结束时间为空且最后是最后一项，正常生产中 -->
                                        <div v-else-if="!i2[1] && index === item['time'].length - 1"
                                            v-tooltip="{ content: i2, placement: 'top', trigger: 'hover' }"
                                            class="bloke scz"
                                            :style="{ border: '1px solid #ccc', left: lf(i2[0]) + 'px', width: '100%' }">
                                        </div>
                                        <!-- 3.判断结束时间不为空且是停机状态，停机时间 -->
                                        <div v-else-if="i2[1] && i2[4] === '停机'"
                                            v-tooltip="{ content: i2, placement: 'top', trigger: 'hover' }" class="bloke"
                                            :style="{ border: '1px solid #ccc', left: lf(i2[0]) + 'px', width: wd(i2) + 'px', background: 'linear-gradient(90deg, #fff 0 ' + (780 / 86400) * stWidth + 'px, rgba(255,0,0) ' + (780 / 86400) * stWidth + 'px) 0px 0px/100% 100%' }">
                                        </div>
                                        <div v-if="!i2[1] && index === item['time'].length - 1"
                                            v-tooltip="{ content: i2, placement: 'top', trigger: 'hover' }"
                                            class="bloke"
                                            :style="{ border: '1px solid #ccc', left: (lf(i2[0]) + i2[2] * 3600/86400 * stWidth)+ 'px', width: '100%' }" style="background-color: rgb(255, 255, 0 ,0.4);">
                                        </div>
                                    </div>
                                    <div v-if="item['time'][item['time'].length - 1][1]" class="bloke"
                                        :style="{ border: '1px solid #ccc', left: lf(item['time'][item['time'].length - 1][1]) + 'px', width: '100%', background: 'linear-gradient(90deg, #fff 0 ' + (780 / 86400) * stWidth + 'px, rgba(255,0,0) ' + (780 / 86400) * stWidth + 'px) 0px 0px/100% 100%' }">
                                    </div>
                                    
                                </div>
                                <div style="width: 26px;">
                                    <div
                                        :class="{ 'blinking-circle3': item['time'][item['time'].length - 1][1] && if_red2(item['time'][item['time'].length - 1][1]), 'blinking-circle2': !item['time'][item['time'].length - 1][1] }">
                                    </div>
                                </div>
                                <div style="width: 120px;font-size: 16px;padding: 0;margin: 0;">{{
                                    countdown(item['time'][item['time'].length - 1][1] ? item['time'][item['time'].length -
                                        1][1] : item['time'][item['time'].length - 1][0]) }}</div>
                                <div
                                    style="width: 100px;font-size: 16px;padding: 5px;margin: 5px;border: 2px solid #d0e343;">
                                    {{
                                        Beyond(item['time']) }}分钟</div>
                            </div>
                        </div>
                    </div>

                </div>
                <!-- 刻度 -->

                <div style="display: flex;flex-direction: row;position: relative;">
                    <div style="width: 96px;font: 14px sans-serif;padding: 12px;"></div>
                    <div ref="myDiv"
                        style="display: block;flex-direction: row; margin-top: 14px;background-color: rgb(79, 111, 198);width: calc(100% - 390px);height: 2px;position: relative;">
                        <div class="kd_m" :style="{ left: lf_kd_m(i) + 'px' }" v-for="i in 144" v-if="lf_kd_m(i) !== false">
                        </div>
                        <div style="border-bottom: 1px solid #000;"></div>

                        <div class="kd" :style="{ left: lf_kd(i) + 'px' }" v-for="i in 120" v-if="lf_kd(i) !== false">{{
                            lf_kd_date(i) }}</div>
                        <!-- <div :style="{ left: lf_kd() }">231</div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.downtime-button {
  padding: 5px !important;
  background-color: #ff4d4f; /* 红色背景 */
  color: #fff; /* 白色文字 */
  border: none;
  border-radius: 5px; /* 圆角边框 */
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* 添加阴影 */
  transition: all 0.3s ease; /* 过渡效果 */
}

.downtime-button:hover {
  background-color: #e9e511; /* 鼠标悬停时的背景色 */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* 鼠标悬停时的阴影 */
}
.rmdiv {
    display: flex;
    flex-direction: row;
    position: relative;
    width: 100%;
    height: 28px;
    overflow: hidden;
    border-top: 2px solid rgb(79, 111, 198);
}

@keyframes blink {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

/* 突变形闪烁 */
.blinking-circle {
    height: 20px;
    width: 20px;
    background-color: #75adf8;
    border-radius: 50%;
    margin-left: 20px;
    animation: blink 1s infinite;
}

@keyframes radialBlink {
    0% {
        box-shadow: 0 0 4px 4px rgba(117, 173, 248, 0.9);
    }

    50% {
        box-shadow: 0 0 8px 8px rgba(117, 173, 248, 0.5);
    }

    100% {
        box-shadow: 0 0 4px 4px rgba(117, 173, 248, 0.9);
    }
}

@keyframes radialBlink2 {
    0% {
        box-shadow: 0 0 4px 4px rgba(232, 84, 77, 0.9);
    }

    50% {
        box-shadow: 0 0 8px 8px rgba(232, 84, 77, 0.5);
    }

    100% {
        box-shadow: 0 0 4px 4px rgba(232, 84, 77, 0.9);
    }
}

/* 径向闪烁 */
.blinking-circle2 {
    height: 10px;
    width: 10px;
    background-color: #75adf8;
    border-radius: 50%;
    margin: 10px;
    animation: radialBlink 1s infinite;
}

.blinking-circle3 {
    height: 10px;
    width: 10px;
    background-color: #e8544d;
    border-radius: 50%;
    margin: 10px;
    animation: radialBlink2 1s infinite;
}

.scz {
    background: repeating-linear-gradient(-45deg, #faf8f8 0 4px, #75adf8 4px 8px) 0px 0px/100% 3000%;
    /* transition: 10.5s linear; */
    animation: slideBackground 30s linear infinite;
}

@keyframes slideBackground {
    0% {
        background-position: 0 0;
    }

    100% {
        background-position: 0 1000px;
    }

}

.biao-ti {
    border-bottom: 5px double rgb(0, 0, 0);
    width: 60%;
    text-align: center;
    margin-bottom: 26px;
    font-size: 22px;
    letter-spacing: 1.2em;
    margin-left: auto;
    margin-right: auto;
    padding: 15px;
}

.kd {
    width: 60px;
    position: absolute;
    top: 22px;
    transform: translate(-50%, -6px);
    font-size: 10px;
    color: #000;
    background-color: #fff;
    padding: 2px 5px;
    border-radius: 5px;
    border: 1px solid #ccc;
    z-index: 1;
}

.kd::before {
    content: "";
    position: absolute;
    left: 50%;
    top: -50%;
    transform: translateY(-50%);
    border-style: solid;
    border-width: 8px;
    border-color: transparent transparent transparent #9f1414;
}

.kd_m {
    height: 8px;
    position: absolute;
    top: -2px;
    transform: translate(-50%, -6px);
    font-size: 10px;
    color: #000;
    background-color: #fff;

    border-radius: 5px;
    border: 1px solid #ccc;
    z-index: 1;
}

/* .kd_m::before {
    content: "";
    position: absolute;
    left: 50%;
    top: -50%;
    transform: translateY(-50%);
    border-style: solid;
    border-width: 8px;
    border-color: transparent transparent transparent #9f1414;
} */

.bloke {
    height: 20px;
    font-size: 8px;
    position: absolute;
    font: 14px sans-serif;
    padding: 0px;

}

.bloke:hover {
    background-color: #7ed4e5;
    color: #ccc;
}

.bubble {
    position: relative;
    border-radius: 5px;
}

.bubble::before {
    content: "";
    position: absolute;
    right: -14px;
    top: 50%;
    transform: translateY(-50%);
    border-style: solid;
    border-width: 8px;
    border-color: transparent #9f1414 transparent transparent;
}

.xux {
    height: 38px;
    width: 60px;
    margin: 5px;
    border-radius: 5px;
    border: 1px solid #ccc;
    background-color: #fff;
    color: #000;
    cursor: pointer;
    outline: none;
    transition: all 0.2s ease-in-out;
}

.xux:hover {
    background-color: #7ed4e5;
    color: #cf55aa;
}
</style>
<script>

import { getdata } from '../api'

export default {
    computed: {
        pickerOptions() {
            return {
                shortcuts: [
                    {
                        text: '昨日',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24);
                            picker.$emit('pick', [start, end]);
                            start.setHours(7, 0, 0, 0); // 设置为早上7点
                            end.setHours(7, 0, 0, 0); // 设置为前一天的晚上7点
                        }
                    }, {
                        text: '最近一周',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近一个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近三个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                            picker.$emit('pick', [start, end]);
                        }
                    }]
            };
        },
        // gdheight() {

        //     return this.$refs.wrapper.clientHeight
        // }
    },
    filters: {
        removeChinese(text) {
            const pattern = /[\u4e00-\u9fa5]+/g; // 匹配汉字的正则表达式
            return text.replace(pattern, ''); // 使用replace函数将匹配到的汉字替换为空字符串
        }
    },
    // 监听器
    watch: {
        // value2: {
        //     handler(newValue, oldValue) {
        //         // 在侦听器中监听数组的变化
        //         // newValue 是新的数组值
        //         // oldValue 是旧的数组值
        //         // 在这里可以调用你想要执行的方法
        //         this.submit(this.xz);
        //     },
        //     deep: true 
        // }
        // 监听this.$refs.wrapper.clientHeight的变化
        'mydata'(newHeight, oldHeight) {
            setTimeout(() => {
                // console.log(this.gdheight, 'this.gdheight222222')
                if (this.$refs.wrapper?.clientHeight) {
                    this.gdheight = this.$refs.wrapper.clientHeight;
                }
            }, 2000); // 2秒延迟
        }
    },
    data() {
        return {
            // 页面滚动
            offset: 0,
            gdheight: 0,
            gdqzTransition: 'transform 0.5s ease',
            isPaused: false, // 控制动画是否暂停
            xz: '数车',
            chek: [
                '数车',
                '数铣',
                '精雕',
                '切割',
                '车铣'
            ],
            star: '',
            top: '',
            stWidth: '',
            if_for: false,
            mydata: [
                {
                    name: '切割机Q1',
                    time: [['2021-01-01 12:00:00', '2021-01-01 13:00:00', '-2', '姓名1'], ['2021-01-01 13:10:00', '2021-01-01 14:00:00', '2', '姓名2'], ['2021-01-01 14:10:00', '2021-01-01 17:00:00', '1', '姓名3']]
                },
                {
                    name: '切割机Q2',
                    time: [['2021-01-01 10:00:00', '2021-01-01 13:00:00', '-2', '姓名1'], ['2021-01-01 12:10:00', '2021-01-01 14:00:00', '2', '姓名2'], ['2021-01-01 14:10:00', '2021-01-01 16:00:00', '1', '姓名3']]
                },
                {
                    name: '切割机Q3',
                    time: [['2021-01-01 12:00:00', '2021-01-01 13:00:00', '-2', '姓名1'], ['2021-01-01 13:10:00', '2021-01-01 14:00:00', '2', '姓名2'], ['2021-01-01 14:10:00', '2021-01-01 17:00:00', '1', '姓名3']]
                },
                {
                    name: '切割机Q4',
                    time: [['2021-01-01 12:00:00', '2021-01-01 13:00:00', '-2', '姓名1'], ['2021-01-01 13:10:00', '2021-01-01 14:00:00', '2', '姓名2'], ['2021-01-01 14:10:00', '2021-01-01 17:00:00', '1', '姓名3']]
                }
            ],
            // value2: [new Date(2021, 1, 1, 1, 10), new Date(2000, 10, 11, 10, 10)],
            targetTime: new Date('2024-03-05T10:00:00').getTime(), // 设置目标时间--------------可以不要
            currentTime: new Date().getTime() // 获取当前时间
        };
    },
    methods: {
        if_red(data) {
            let timeDiff = this.currentTime - new Date(data); // 计算时间差（毫秒）
            // 12小时转换为毫秒
            let twelveHoursInMilliseconds = 12 * 60 * 60 * 1000;
            // 返回是否大于12小时
            return timeDiff > twelveHoursInMilliseconds;
        },
        if_red2(data) {
            let timeDiff = this.currentTime - new Date(data); // 计算时间差（毫秒）
            // 12小时转换为毫秒
            let twelveHoursInMilliseconds = 13* 60 * 1000;
            // 返回是否大于12小时
            return timeDiff > twelveHoursInMilliseconds;
        },
        Beyond(timeList) {
            let totalExtraTime = 0;

            timeList.forEach(item => {
                if (item[4] === '停机') {
                    const startTime = new Date(item[0]);
                    const endTime = new Date(item[1]);
                    const timeDifference = (endTime - startTime) / (1000 * 60); // 计算分钟数

                    if (timeDifference > 13) {
                        const extraTime = timeDifference - 13;
                        totalExtraTime += extraTime;
                    }
                }
            });
            if(timeList[timeList.length - 1][1]){
                const starTime = new Date(timeList[timeList.length - 1][1]);
                const endTime = new Date(this.top);
                const timeDifference = (endTime - starTime) / (1000 * 60); // 计算分钟数
                if (timeDifference > 13) {
                    const extraTime = timeDifference - 13;
                    totalExtraTime += extraTime;
                }
            }
            //取小数点后两位
            return totalExtraTime.toFixed(2);
        },
        countdown(data) {
            let timeDiff = this.currentTime - new Date(data); // 计算时间差（毫秒）

            // 将时间差转换为天、小时、分钟和秒
            let hours = Math.floor(timeDiff / (1000 * 60 * 60));
            let minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
            let seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);

            // 返回格式化的倒计时字符串
            return `${hours} 小时 ${minutes} 分钟 ${seconds} 秒`;
        },
        lf_kd(i) {
            const starTime = new Date(this.star);
            const endTime = new Date(starTime.getTime() + (i - 1) * 24 * 60 * 60 * 1000);
            const lf = (endTime - new Date(this.star)) / (new Date(this.top) - new Date(this.star))
            if (lf > 1) {
                return false
            } else {
                return lf * this.stWidth
            }
        },
        lf_kd_m(i) {
            const starTime = new Date(this.star);
            const endTime = new Date(starTime.getTime() + (i - 1) * 2 * 60 * 60 * 1000);
            const lf = (endTime - new Date(this.star)) / (new Date(this.top) - new Date(this.star))
            if (lf > 1) {
                return false
            } else {
                return lf * this.stWidth
            }
        },
        lf_kd_date(i) {
            const starTime = new Date(this.star);
            const date = new Date(starTime.getTime() + (i - 1) * 24 * 60 * 60 * 1000);
            return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}`
        },
        lf(data) {
            return (new Date(data) - new Date(this.star)) / (new Date(this.top) - new Date(this.star)) * this.stWidth
        },
        wd(data) {
            return (new Date(data[1]) - new Date(data[0])) / (new Date(this.top) - new Date(this.star)) * this.stWidth
        },
        submit(xx) {
            this.xz = xx;
            const ks = this.formatDate(new Date(this.star));
            const js = this.formatDate(new Date(this.top));
            const formattedDates = [ks, js]
            const data = {
                shijian: formattedDates
            }
            getdata({ ...data, operate: '5454' }).then(res => {
                if (res.data?.message === "会话失效") {
                    //清楚cookie的token信息
                    Cookie.remove('token')
                    //清楚Cookie中的menu
                    Cookie.remove('menu')
                    //跳转到登录页
                    this.$router.push('/login')
                    console.log('zhixl')
                }
                else {
                    this.mydata = res.data
                }

            })
        },
        updateWidth() {
            this.stWidth = this.$refs.myDiv.offsetWidth;
            console.log(this.stWidth, 'this.stWidth')
        },
        formatDate(date) {
            const year = date.getFullYear();
            const month = (date.getMonth() + 1).toString().padStart(2, '0'); // 月份从0开始，需要加1
            const day = date.getDate().toString().padStart(2, '0');
            const hours = date.getHours().toString().padStart(2, '0');
            const minutes = date.getMinutes().toString().padStart(2, '0');
            const seconds = date.getSeconds().toString().padStart(2, '0');
            return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        },
        startAnimation() {
            const intervalId = setInterval(() => {
                // 如果isPaused为true，则阻塞动画
                if (this.isPaused) {
                    return;
                }
                this.offset -= 57.33; // 每次向上滚动x个像素
                this.gdqzTransition = 'transform 0.5s ease';
                if (this.offset < -this.gdheight + 10) {
                    setTimeout(() => {
                        this.gdqzTransition = 'none'; // 去掉动画效果
                        this.offset = 0;
                    }, 500);// 等待最后一个动画结束，然后瞬间跳回顶部
                }
            }, 2500); // 控制滚动速度的时间间隔
            // 保存intervalId以便之后清除
            this.intervalId = intervalId;
        },
        pauseAnimation() {
            this.isPaused = true;
        },
        resumeAnimation() {
            this.isPaused = false;
        },
    },
    mounted() {

        // 添加事件监听器
        window.addEventListener('resize', this.updateWidth);
        // 初始执行一次，以便于处理当前视口大小
        this.updateWidth();
        this.currentTime = new Date().getTime(); // 每秒更新当前时间
        // 前两天的时间
        this.star = new Date(this.currentTime - 2 * 24 * 60 * 60 * 1000);
        this.top = this.currentTime
        this.submit('数铣')
        const intervalId2 = setInterval(() => {
            this.currentTime = new Date().getTime(); // 每秒更新当前时间
            // 前两天的时间
            this.star = new Date(this.currentTime - 1 * 24 * 60 * 60 * 1000);
            this.top = this.currentTime
            this.submit()
        }, 1000);
        this.intervalId2 = intervalId2;
        this.startAnimation();
        if (this.$refs.jsgd && this.$refs.jsgd.length > 0) {
            // 访问数组的第一个元素，获取其高度
            const element = this.$refs.jsgd[0];

            // 获取offsetHeight
            const offsetHeight = element.offsetHeight;

            // 获取计算后的样式
            const style = window.getComputedStyle(element);

            // 从计算后的样式中提取上下外边距并转换为整数
            const marginTop = style.marginTop;
            const marginBottom = style.marginBottom;

            // 计算总高度
            const totalHeight = offsetHeight + marginTop + marginBottom;

            console.log("Total height with margins: " + totalHeight + "px");
        }


    },

    beforeDestroy() {
        // this.$unwatch('mydata');
        // 移除事件监听器
        window.removeEventListener('resize', this.updateWidth);

        // 组件销毁前清除定时器，避免内存泄露
        clearInterval(this.intervalId);
        clearInterval(this.intervalId2);
    }
}
</script>